%box-white-sm {
  display: inline-block;
  background-color: $color-white;
  border-radius: $border-radius-sm;
  width: 100%;
  padding: 0 $space $space $space;
}

%box-with-shadow {
  box-shadow: 0px 0px 30px rgba(69, 81, 99, 0.5);
}

%box-with-shadow-sm {
  box-shadow: 0px 2px 10px -4px rgba(69, 81, 99, 0.5);
}

// Texts
%h1-text {
  font-family: $primary-font;
  color: $color-grey-03;
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 400;
  margin: $space 0 0;
  padding: 0;
}

%simple-strong-text {
  font-family: $primary-font;
  font-weight: 400;
  color: $color-grey-03;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  padding: 0;
}

/**
 * Material Type Scale:
 * The type scale is a combination of 13 styles that are supported by the type system.
 * It contains reusable categories of text, each with an intended application and meaning.
 * https://material.io/design/typography/the-type-system.html#type-scale
 */

%type-material-h1 {
  font-family: $primary-font;
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -1.5px;
}

%type-material-h2 {
  font-family: $primary-font;
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.5px;
}

%type-material-h3 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 0px;
}

%type-material-h4 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
}

%type-material-h5 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0px;
}

%type-material-h6 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.15px;
}

%type-material-subtitle-1 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}

%type-material-subtitle-2 {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
}

%type-material-body-1 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
}

%type-material-body-2 {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

%type-material-button {
  font-family: $primary-font;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

%type-material-caption {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

%type-material-overline {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

%material-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 0.5rem;
  position: relative;
}
