// Modals
.mat-dialog-container {
  padding: 0 !important;

  min-width: 500px !important;
  max-height: 95vh !important;

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.modal-header {
  width: 100%;

  position: sticky;
  display: inline-block;

  top: 0;
  left: 0;

  padding: $space-sm;
  line-height: 175%;

  z-index: 999;

  color: #2a3439;
  background-color: #ecf0f1;

  .mat-icon {
    color: #2a3439;
  }

  .modal-title {
    position: relative;

    max-width: calc(100% - 30px);
    z-index: 998;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .close {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: $space-sm;

    height: 27px;
    width: 27px;

    border: none;
    outline: none;

    cursor: pointer;

    border-radius: 3px;

    color: $color-white;
    background-color: transparent;

    background-color: #e8e8e8;
    text-align: center;

    padding: 0;

    mat-icon {
      height: 100%;
    }

    &:hover {
      background-color: #e2e2e2;
      transition: all 0.2s linear;
    }
  }
}

.content-left {
  padding-right: $space;
  border-right: 1px solid $color-grey-04;
}

.modal-wrapper {
  padding: $space-sm $space $space $space;

  .mat-input-element:disabled {
    color: inherit;
  }
}

.modal-footer {
  padding: $space-sm $space $space $space;

  button {
    margin-right: 10px;
    min-height: 38px;
    vertical-align: top;

    &:last-of-type {
      margin-right: none;
    }

    mat-spinner {
      margin: 0 auto;
    }
  }
}
