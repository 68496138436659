#app-top-bar {
  height: 95px;

  margin-top: -32px;

  background-color: white;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.08);

  padding: 17px 20px 0 45px;

  color: #455163;

  position: relative;

  z-index: 0;
  p, h1 {
    margin: 0;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }

  button {
    position: absolute;
    right: 20px;
    top: 30px;

    padding-left: 10px;
  }
}

#app-main-content {
  padding: 25px;
}
