$primary-font: Roboto, "Helvetica Neue", SansSerif;

$border-radius-sm: 4px;

$color-white: #ffffff;
$color-green: #28920b;
$color-yellow: #c4ac10;
$color-orange: #92610b;
$color-red: #920b0b;

$color-grey-01: #7b90b3;
$color-grey-02: #647691;
$color-grey-03: #455163;
$color-grey-04: #d6d6d6;
$color-grey-05: #eef5f9;
$color-grey-06: #fafafa;

$color-blue-01: #639fff;
$color-blue-02: #3f7ad9;
$color-blue-03: #2259b3;
$color-blue-04: #0b3f92;
$color-blue-material: #3445a3;

$color-error: #f44336;
$color-ok: #4caf50;
$color-warning: #f9a825;

$space: 30px;
$space-sm: 15px; // Debe ser la mitad de espacio que $space;
$space-xl: 60px; // Debe ser el doble de espacio que $space;
