@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "styling/variables.scss";
@import "styling/extensions.scss";
@import "styling/layout.scss";
@import "styling/modal.scss";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Roboto:400,500,700&display=swap&subset=latin-ext');

html {
  min-height: 100vh;

  // Scroll-bar Personalizada.
  ::-webkit-scrollbar {
    width: 0.35em;
    height: 0.35em;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-grey-01;
    outline: 1px solid $color-grey-03;
  }
}

body {
  min-height: 100%;
  min-width: 1200px;

  margin: 0;
  padding: 0;

  font-family: Roboto, "Helvetica Neue", SansSerif;

  background: $color-grey-06;

  &.login-page {
    min-width: 360px;
  }

  .no-truncate {
    white-space: normal !important;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }

  .h-100 {
    height: 100%!important;
  }

  .align-middle {
    vertical-align: middle!important;
  }

  // Tab Label
  .mat-tab-label {
    background-color: $color-grey-06;
    border: 1px solid $color-grey-04;
    border-bottom: none;

    &.mat-tab-label-active,
    &.mat-tab-label-active:focus {
      background-color: $color-white;
      font-weight: 800;
      color: $color-blue-04;
      opacity: 1;

      .qty {
        background-color: $color-blue-03;
        color: $color-white;
      }
    }

    .qty {
      margin-left: 4px;
      font-weight: 400;
      position: absolute;
      top: 5px;
      border-radius: 100%;
      line-height: 200%;
      min-width: 19px;
      height: 19px;
      font-size: 65%;
      background-color: $color-grey-03;
      color: $color-white;
    }
  }

  .mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
  .mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
    background-color: $color-white;
  }

  .side-menu-nav {
    .mat-list-base .mat-list-item .mat-list-item-content {
      padding: 0 10px;
    }
    .mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
      padding-left: 10px;
    }
  }

  mat-row.no-clickeable-row {
    cursor: auto;

    .mat-cell {
      color: $color-grey-03;
    }
  }

  .mat-tab-body-wrapper {
    background-color: white;
    border-top: none;

    .mat-row:nth-child(even) {
      background-color: $color-grey-06;
    }

    .clickeable-row {
      cursor: pointer;

      &:hover {
        background-color: $color-grey-05;
      }

      .mat-cell {
        color: $color-blue-02;
      }
    }

    .mat-sort-header-button:focus {
      background-color: transparent;
    }
  }

  input:not([type]):focus:not([readonly]) + label,
  input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
  input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: $color-blue-material;
  }
  .input-field .prefix.active {
    color: $color-blue-material;
  }

  .mat-form-field {
    .mat-icon {
      vertical-align: -4px;
      margin-right: 20px;
      color: $color-grey-02;
    }
  }

  .checkboxes-wrap {
    margin: $space 0;
  }

  // Mat Tables
  .mat-table {
    overflow: auto;

    .cellCenter {
      display: flex;
      justify-content: center;
      button {
        padding-left: 12px;
      }
    }
  }

  // Estados de Solicitudes
  .estado-solicitudes {
    &.estado-w-border {
      color: $color-blue-03;
      border: 1px solid $color-blue-03;
      padding: 3px 10px;
      min-width: 95px;
      text-align: center;
      text-transform: capitalize;
    }
    &.estado-pendiente {
      background-color: $color-green;
      color: $color-yellow;
      border-color: $color-yellow;
    }
    &.estado-rechazado {
      color: $color-red;
      border-color: $color-red;
    }
    &.estado-devuelto {
      color: $color-orange;
      border-color: $color-orange;
    }
    &.estado-finalizado {
      color: $color-green;
      border-color: $color-green;
    }
    &.estado-modificado {
      background-color: rgb(248, 248, 71);
      color: $color-green;
      border-color: $color-green;
    }
    &.estado-solicitado {
      color:rgb(248, 248, 71);
      background-color: $color-green;
      border-color: rgb(0, 104, 17);;
    }
    .search-results {
      height: 100%;
    }
  }

  // Criticidad Colors
  .criticidad {
    text-transform: capitalize;
    &.criticidad-1 {
      color: $color-red;
    }
    &.criticidad-2 {
      color: $color-orange;
    }
    &.criticidad-3 {
      color: $color-yellow;
    }
    &.criticidad-4 {
      color: $color-green;
    }
  }

  // Banda de Toxicidad
  .banda {
    position: absolute;
    right: 0;
    top: 0px;
    width: 5px;
    height: calc(100%);
    text-indent: -400px;
    overflow: hidden;
    background-color: $color-grey-01;
    &.banda-amarillo {
      background-color: $color-yellow;
    }
    &.banda-azul {
      background-color: $color-blue-02;
    }
    &.banda-verde {
      background-color: $color-green;
    }
  }
}

.banda-text {
  &.banda-amarillo {
    color: $color-yellow;
  }
  &.banda-azul {
    color: $color-blue-02;
  }
  &.banda-verde {
    color: $color-green;
  }
}

// Utilidades Cursor:
.clickeable {
  cursor: pointer;
}

// Utilidades Texto:
.font-bold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// Utilidades Elementos Flotantes:
.left {
  float: left;
}

.right {
  float: right;
}

// Estilos Área Contenido Principal:
#content-area {
  margin-top: $space;
  margin-bottom: $space;
  position: relative;
}

.content-margin {
  margin-left: $space;
  margin-right: $space;
}

.mat-flat-button {
  .mat-icon {
    vertical-align: middle;
    font-size: 160%;
  }
}

.mat-tab-header {
  border-bottom: 0 !important;
}

.grecaptcha-badge {
  display: none !important;
}

.map-tooltip {
  position: absolute;
  border-radius: 3px;

  color: #787777;
  background-color: rgba(255, 255, 255, 0.8);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  z-index: 3;

  .tt-title {
    padding: 5px 10px;
    background-color: #f8f8ff;

    font-size: 13px;
    font-weight: bold;
    color: #2699fb;
  }

  .tt-body {
    padding: 10px;
  }

  h1,
  p {
    margin: 0;
  }

  h1 {
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
  }

  p {
    font-size: 11px;
  }
}
